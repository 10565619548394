import React, { useEffect, useState } from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { Button, Col, ConfigProvider, Layout, Row } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import SideBar from './components/SideBar';
import { Logo } from './images/Logo';
import { Route, Routes } from 'react-router';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { MonitorsList } from './monitors/monitorsList';
import { TeamsList } from './teams/teamsList';
import { UsersList } from './users/usersList';
import { authorized } from './model/api_gen';
import axios from 'axios';

function App() {

  const [isAuthorized, setIsAuthorized] = useState<boolean>();

  useEffect(() => {
    if (isAuthorized === undefined) {
      axios.interceptors.response.use((res) => res,
        (error) => {
          if (error.response.status === 401) {
            setIsAuthorized(false);
          }
          return Promise.reject(error);
      })
      authorized()
        .then(() => setIsAuthorized(true))
        .catch(() => setIsAuthorized(false))
    }

  }, [isAuthorized]);

  return (
    !isAuthorized ?
      <p>Page not found</p>
    :
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
          colorLink: '#00b96b',
          colorLinkHover: '#00b96b',
          colorBgLayout: '#F8F8F8',
          colorFillQuaternary: '#E6E6E6',
        },
      }}
    >
      <BrowserRouter>
        <div className="App">
          <Layout style={{minHeight: '100vh'}}>
            <Header
              style={{
                display: "flex",
                height: "60px",
                lineHeight: "0px",
                background: "#FFFFFF",
                padding: "10px",
              }}
            >
              <Row justify="space-between" style={{width: "100%"}}>
                <Col flex="180px">
                  <Logo />
                </Col>
                <Col flex="none">
                  <Button onClick={() => {}} type="link">Logout</Button>
                </Col>
              </Row>
            </Header>
            <Layout>
              <Sider theme="light">
                <SideBar />
              </Sider>
              <Content style={{padding: "40px"}}>
                <Routes>
                  <Route path="/" element={<TeamsList />} />
                  <Route path="/teamsList" element={<TeamsList />} />
                  <Route path="/monitorsList" element={<MonitorsList />} />
                  <Route path="/usersList" element={<UsersList />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Content>
            </Layout>
            <Footer className="celda">Footer</Footer>
          </Layout>
        </div>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;