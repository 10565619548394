import React, { useCallback, useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import { DateTime } from "luxon";
import { Col, Pagination, Radio, Row, Spin, Table } from "antd";
import { Typography } from 'antd';
import { useDebounce } from "../hooks/useDebounce";
import { getMonitors } from "../model/api_gen";
import { GetMonitorRes, PaginatorRes } from "../model/dtos_gen";

export const MonitorsList: React.FC = () => {

  const { Title } = Typography;
  const [monitors, setMonitors] = useState<GetMonitorRes[] | undefined>();
  const [status, setStatus] = useState('');
  const [paginator, setPaginator] = useState<PaginatorRes | undefined>();
  const [queryText, setQueryText] = useState("");
  const debouncedQueryText = useDebounce(queryText, 500);

  const filterOptions = [
    { label: 'All', value: '' },
    { label: 'Up', value: 'up' },
    { label: 'Degraded', value: 'degraded' },
    { label: 'Disrupted', value: 'disrupted' },
    { label: 'Down', value: 'down' },
    { label: 'Maintenance', value: 'maintenance' },
  ];

  // const req: TextQueryReq = {text: text, recordOffset: recordOffset}

  const fetch = useCallback((text: string = "", recordOffset: number = 0) => {
    getMonitors(
      queryText,
      0,
      0,
      "",
      status,
      "",
      "",
      "",
      false,
      false,
      0
    )
      .then(res => {
        setMonitors(res.monitors);
        setPaginator(res.paginator);
      })
      .catch(err => console.log(err));
  }, [queryText, status]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Uuid",
      dataIndex: "uuid",
      key: "uuid",
      ellipsis: true,
    },
    {
      title: "Monitor",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Urn",
      dataIndex: "urn",
      key: "urn",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
    {
      title: "Interval",
      dataIndex: "interval_id",
      key: "interval_id",
      ellipsis: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      render: (text: any) => <>{DateTime.fromISO(text).toLocaleString()}</>
    },
  ];

  useEffect(() =>
    fetch(debouncedQueryText),
    [fetch, debouncedQueryText]);

  return (
    <>
      <Row>
        <Col flex="auto">
          <Row>
            <Col style={{flex: "none"}}>
              <Title level={2} style={{marginBottom: "0px"}}>
                Monitors List
              </Title>
            </Col>
            <Col style={{paddingLeft: "32px", flex: "auto"}}>
              <Search
                value={queryText}
                onChange={(event) => setQueryText(event.target.value)}
                placeholder="Search monitor..."
                allowClear />
            </Col>
          </Row>
          <Row>
            <Col style={{paddingTop: "16px", flex: "none"}}>
              <Row>
                <Title level={4} style={{marginBottom: "0px", paddingRight: "16px"}}>
                  Filter By
                </Title>
                <Radio.Group
                  options={filterOptions}
                  onChange={(event) => setStatus(event.target.value)}
                  value={status}
                  optionType="button"
                  buttonStyle="solid" />
              </Row>
            </Col>
          </Row>
          {!monitors && !debouncedQueryText ?
            <Row justify="center" style={{width: "100%", paddingTop: "64px"}}>
              <Spin />
            </Row>
          : !monitors && debouncedQueryText ?
            <Row>
              <Col style={{paddingTop: "32px"}}>
                No result. Try your search again with more specific keywords.
              </Col>
            </Row>
          : monitors && paginator ?
            <Col>
              <Row style={{paddingTop: "16px"}}>
                <Table
                  scroll={{x: true}}
                  dataSource={monitors}
                  rowKey="id"
                  columns={columns}
                  style={{width: "100%"}}
                  pagination={false} />
              </Row>
              <Row justify="end"  style={{paddingTop: "16px", width: "100%"}}>
                <Pagination
                  total={paginator.recordsCount}
                  current={paginator.pageOffset+1}
                  onChange={(page) => fetch(debouncedQueryText, (page - 1) * paginator.pageSize)}
                  showSizeChanger={false}
                  pageSize={paginator.pageSize} />
              </Row>
            </Col>
          : null }
        </Col>
      </Row>
    </>
  )
}