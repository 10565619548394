import axios from 'axios';

import {
  GetMonitorRes,
  GetMonitorsByStatusRes,
  GetMonitorsRes,
  GetTeamRes,
  GetTeamsRes,
  GetUserRes,
  GetUsersRes,
  PaginatorReq
} from "./dtos_gen"

const apiBase = process.env.REACT_APP_API_BASE_URL;

export const authorized = (): Promise<number> => {
  return axios.get(`${apiBase}/authorized`)
    .then(res => res.status)
    .catch(err => { throw(err) });
}
export const getMonitor = (monitorId:  number): Promise<GetMonitorRes> => {
  return axios.get(`${apiBase}/monitors/${monitorId}`)
    .then(res => res.data as GetMonitorRes)
    .catch(err => { throw(err) });
}
export const getMonitors = (text:  string, recordOffset:  number, pageSize:  number, order:  string, status:  string, groupUuid:  string, pageUuid:  string, alertUuid:  string, descending:  boolean, short:  boolean, teamId:  number): Promise<GetMonitorsRes> => {
  return axios.get(`${apiBase}/monitors`, {params: {  text: text  ,  recordOffset: recordOffset  ,  pageSize: pageSize  ,  order: order  ,  status: status  ,  groupUuid: groupUuid  ,  pageUuid: pageUuid  ,  alertUuid: alertUuid  ,  descending: descending  ,  short: short  ,  teamId: teamId   } } )
    .then(res => res.data as GetMonitorsRes)
    .catch(err => { throw(err) });
}
export const getMonitorsByStatus = (status:  number, paginator:  PaginatorReq): Promise<GetMonitorsByStatusRes> => {
  return axios.get(`${apiBase}/monitors/${status}/status`, {params: { paginator  } } )
    .then(res => res.data as GetMonitorsByStatusRes)
    .catch(err => { throw(err) });
}
export const getTeam = (teamId:  number): Promise<GetTeamRes> => {
  return axios.get(`${apiBase}/teams/${teamId}`)
    .then(res => res.data as GetTeamRes)
    .catch(err => { throw(err) });
}
export const getTeams = (text:  string): Promise<GetTeamsRes> => {
  return axios.get(`${apiBase}/teams`, {params: {  text: text   } } )
    .then(res => res.data as GetTeamsRes)
    .catch(err => { throw(err) });
}
export const getUser = (userId:  number): Promise<GetUserRes> => {
  return axios.get(`${apiBase}/users/${userId}`)
    .then(res => res.data as GetUserRes)
    .catch(err => { throw(err) });
}
export const getUsers = (text:  string): Promise<GetUsersRes> => {
  return axios.get(`${apiBase}/users`, {params: {  text: text   } } )
    .then(res => res.data as GetUsersRes)
    .catch(err => { throw(err) });
}
