import React, { useCallback, useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import { DateTime } from "luxon";
import { Col, Pagination, Row, Spin, Table } from "antd";
import { Typography } from 'antd';
import { useDebounce } from "../hooks/useDebounce";
import { getUsers } from "../model/api_gen";
import { GetUserRes, PaginatorRes } from "../model/dtos_gen";
import { ColumnsType } from "antd/es/table";

export const UsersList: React.FC = () => {

  const { Title } = Typography;
  const [users, setUsers] = useState<GetUserRes[] | undefined>();
  const [paginator, setPaginator] = useState<PaginatorRes | undefined>();
  const [queryText, setQueryText] = useState("");
  const debouncedQueryText = useDebounce(queryText, 500);

  const fetch = useCallback((text: string = "", recordOffset: number = 0) => {
    getUsers(text)
      .then(res => {
        setUsers(res.users);
        setPaginator(res.paginator);
      })
      .catch(err => console.log(err));
  }, []);

  const columns: ColumnsType<GetUserRes> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Uuid",
      dataIndex: "uuid",
      key: "uuid",
      ellipsis: true,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      ellipsis: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      render: (text: any) => <>{DateTime.fromISO(text).toLocaleString()}</>
    },
  ];

  useEffect(() => {
    fetch(debouncedQueryText);
  }, [fetch, queryText, debouncedQueryText]);

  return (
    <>
      <Row>
        <Col flex="auto">
          <Row>
            <Col style={{flex: "none"}}>
              <Title level={2} style={{marginBottom: "0px"}}>
                Users List
              </Title>
            </Col>
            {!users && !debouncedQueryText ?
              null
            :
            <Col style={{paddingLeft: "32px", flex: "auto"}}>
              <Search
                value={queryText}
                onChange={(event) => setQueryText(event.target.value)}
                placeholder="Search user..."
                allowClear />
            </Col>
            }
          </Row>
          {!users && !debouncedQueryText ?
            <Row justify="center" style={{width: "100%", paddingTop: "64px"}}>
              <Spin />
            </Row>
          : !users && debouncedQueryText ?
            <Row>
              <Col style={{paddingTop: "32px"}}>
                No result. Try your search again with more specific keywords.
              </Col>
            </Row>
          : users && paginator ?
            <Col>
              <Row style={{paddingTop: "16px"}}>
                <Table
                  scroll={{x: true}}
                  dataSource={users}
                  rowKey="id"
                  columns={columns}
                  style={{width: "100%"}}
                  pagination={false} />
              </Row>
              <Row justify="end"  style={{paddingTop: "16px", width: "100%"}}>
                <Pagination
                  total={paginator.recordsCount}
                  current={paginator.pageOffset+1}
                  onChange={(page) => fetch(debouncedQueryText, (page - 1) * paginator.pageSize)}
                  showSizeChanger={false}
                  pageSize={paginator.pageSize} />
              </Row>
            </Col>
          : null }
        </Col>
      </Row>
    </>
  )
}