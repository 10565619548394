import React, { useCallback, useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import { DateTime } from "luxon";
import { Col, Pagination, Row, Spin, Table } from "antd";
import { Typography } from 'antd';
import { useDebounce } from "../hooks/useDebounce";
import { getTeams } from "../model/api_gen";
import { GetTeamRes, PaginatorRes } from "../model/dtos_gen";
import { ColumnsType } from "antd/es/table";

export const TeamsList: React.FC = () => {

  const { Title } = Typography;
  const [teams, setTeams] = useState<GetTeamRes[] | undefined>();
  const [paginator, setPaginator] = useState<PaginatorRes | undefined>();
  const [queryText, setQueryText] = useState("");
  const debouncedQueryText = useDebounce(queryText, 500);

  const fetch = useCallback((text: string = "", recordOffset: number = 0) => {
    getTeams(text)
      .then(res => {
        setTeams(res.teams);
        setPaginator(res.paginator);
      })
      .catch(err => console.log(err));
  }, []);

  const columns: ColumnsType<GetTeamRes> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Uuid",
      dataIndex: "uuid",
      key: "uuid",
      ellipsis: true,
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      ellipsis: true,
    },
    {
      title: "Is Workspace",
      dataIndex: "isWorkspace",
      key: "isWorkspace",
      ellipsis: true,
      render: (text, teams) =>
        <>
          {teams.isWorkspace ? "Workspace" : "Team"}
        </>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      ellipsis: true,

    },
    {
      title: "Balance from",
      dataIndex: "balance_from",
      key: "balance_from",
      ellipsis: true,
      render: (text: any) => <>{text ? DateTime.fromISO(text).toLocaleString() : ""}</>
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      render: (text: any) => <>{DateTime.fromISO(text).toLocaleString()}</>
    },
  ];

  useEffect(() => {
    fetch(debouncedQueryText);
  }, [fetch, queryText, debouncedQueryText]);

  return (
    <>
      <Row>
        <Col flex="auto">
          <Row>
            <Col style={{flex: "none"}}>
              <Title level={2} style={{marginBottom: "0px"}}>
                Teams List
              </Title>
            </Col>
            {!teams && !debouncedQueryText ?
              null
            :
            <Col style={{paddingLeft: "32px", flex: "auto"}}>
              <Search
                value={queryText}
                onChange={(event) => setQueryText(event.target.value)}
                placeholder="Search team..."
                allowClear />
            </Col>
            }
          </Row>
          {!teams && !debouncedQueryText ?
            <Row justify="center" style={{width: "100%", paddingTop: "64px"}}>
              <Spin />
            </Row>
          : !teams && debouncedQueryText ?
            <Row>
              <Col style={{paddingTop: "32px"}}>
                No result. Try your search again with more specific keywords.
              </Col>
            </Row>
          : teams && paginator ?
            <Col>
              <Row style={{paddingTop: "16px"}}>
                <Table
                  scroll={{x: true}}
                  dataSource={teams}
                  rowKey="id"
                  columns={columns}
                  style={{width: "100%"}}
                  pagination={false} />
              </Row>
              <Row justify="end"  style={{paddingTop: "16px", width: "100%"}}>
                <Pagination
                  total={paginator.recordsCount}
                  current={paginator.pageOffset+1}
                  onChange={(page) => fetch(debouncedQueryText, (page - 1) * paginator.pageSize)}
                  showSizeChanger={false}
                  pageSize={paginator.pageSize} />
              </Row>
            </Col>
          : null }
        </Col>
      </Row>
    </>
  )
}